import React from 'react';
import classNames from 'classnames';
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';

import { FormattedMessage, useIntl } from '../../../util/reactIntl';

import {
  CustomExtendedDataField,
  FieldCheckbox,
  FieldCurrencyInput,
  Form,
  PrimaryButton,
} from '../../../components';

import appSettings from '../../../config/settings';
import { useConfiguration } from '../../../context/configurationContext';
import { formatMoney } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';
import * as validators from '../../../util/validators';

import { isFieldForListingType } from '../../../util/fieldHelpers';
import { EXTENDED_DATA_SCHEMA_TYPES } from '../../../util/types';
import PickupAndDeliveryField from './PickupAndDeliveryField';

import css from './RequestSuggestionForm.module.css';

const { Money } = sdkTypes;

const getPriceValidators = (listingMinimumPriceSubUnits, marketplaceCurrency, intl) => {
  const priceRequiredMsgId = { id: 'RequestSuggestionForm.priceRequired' };
  const priceRequiredMsg = intl.formatMessage(priceRequiredMsgId);
  const priceRequired = validators.required(priceRequiredMsg);

  const minPriceRaw = new Money(listingMinimumPriceSubUnits, marketplaceCurrency);
  const minPrice = formatMoney(intl, minPriceRaw);
  const priceTooLowMsgId = { id: 'RequestSuggestionForm.priceTooLow' };
  const priceTooLowMsg = intl.formatMessage(priceTooLowMsgId, { minPrice });
  const minPriceRequired = validators.moneySubUnitAmountAtLeast(
    priceTooLowMsg,
    listingMinimumPriceSubUnits
  );

  return listingMinimumPriceSubUnits
    ? validators.composeValidators(priceRequired, minPriceRequired)
    : priceRequired;
};

const AddListingFields = props => {
  const { listingType, listingFieldsConfig, intl, disabled } = props;

  const fields = listingFieldsConfig.reduce((pickedFields, fieldConfig) => {
    const { key, schemaType, scope } = fieldConfig || {};

    const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
    const isProviderScope = ['public', 'private'].includes(scope);
    const isTargetListingType = isFieldForListingType(listingType, fieldConfig);

    return isKnownSchemaType && isProviderScope && isTargetListingType
      ? [
          ...pickedFields,
          <CustomExtendedDataField
            disabled={disabled}
            key={key}
            name={key}
            fieldConfig={fieldConfig}
            defaultRequiredMessage={intl.formatMessage({
              id: 'EditListingDetailsForm.defaultRequiredMessage',
            })}
          />,
        ]
      : pickedFields;
  }, []);

  return <>{fields}</>;
};

const renderForm = formRenderProps => {
  // FormRenderProps from final-form
  const {
    formId,
    className,
    rootClassName,
    invalid,
    values,
    handleSubmit,
    authorDisplayName,
    intl,
    listingType,
    marketplaceCurrency,
    unitType,
    listingMinimumPriceSubUnits,
    listingFieldsConfig = [],
    listingTypes,
    submitQuoteInProgress,
    submitQuoteError,
    onChange,
    hideSubmit,
    readonlyForm,
    hideDeliveryFields,
  } = formRenderProps;
  const classes = classNames(rootClassName || css.root, className);

  const priceValidators = getPriceValidators(
    listingMinimumPriceSubUnits,
    marketplaceCurrency,
    intl
  );

  const filteredListingFieldsConfig = listingFieldsConfig.filter(item =>
    ['size', 'quality'].includes(item.key)
  );

  const finalFieldsConfig = filteredListingFieldsConfig.map(fieldConfig => {
    if (fieldConfig.key === 'size') {
      return {
        ...(fieldConfig || {}),
        saveConfig: {
          ...(fieldConfig?.saveConfig || {}),
          label: intl.formatMessage({ id: 'RequestSuggestionForm.customSizeLabel' }),
          placeholderMessage: intl.formatMessage({
            id: 'RequestSuggestionForm.customSizePlaceholder',
          }),
        },
      };
    } else {
      return fieldConfig;
    }
  });

  return (
    <Form id={formId} onSubmit={handleSubmit} className={classes}>
      <FormSpy onChange={onChange} />
      <FieldCurrencyInput
        disabled={readonlyForm}
        id={`suggest-price`}
        name="price"
        className={css.input}
        autoFocus={true}
        label={intl.formatMessage({ id: 'RequestSuggestionForm.pricePerProduct' }, { unitType })}
        placeholder={intl.formatMessage({ id: 'RequestSuggestionForm.priceInputPlaceholder' })}
        currencyConfig={appSettings.getCurrencyFormatting(marketplaceCurrency)}
        validate={priceValidators}
      />

      <AddListingFields
        disabled={readonlyForm}
        listingType={listingType}
        listingFieldsConfig={finalFieldsConfig}
        intl={intl}
      />

      {!hideDeliveryFields && (
        <PickupAndDeliveryField
          disabled={readonlyForm}
          formId={formId}
          intl={intl}
          listingTypes={listingTypes}
          listingType={listingType}
          values={values}
          marketplaceCurrency={marketplaceCurrency}
        />
      )}

      {!hideDeliveryFields && (
        <FieldCheckbox
          id="donations"
          name="donations"
          className={css.checkbox}
          label={intl.formatMessage({
            id: 'RequestSuggestionForm.donationForSchoolFundraising',
          })}
          value="donationForSchoolFundraising"
        />
      )}

      {!hideSubmit && (
        <div className={css.submitButton}>
          <div className={css.errorText}>{submitQuoteError?.message}</div>
          <PrimaryButton disabled={invalid} type="submit" inProgress={submitQuoteInProgress}>
            <FormattedMessage
              id={'RequestSuggestionForm.ctaButton'}
              values={{ authorDisplayName }}
            />
          </PrimaryButton>
        </div>
      )}
    </Form>
  );
};

const RequestSuggestionForm = props => {
  const intl = useIntl();
  const config = useConfiguration();

  return (
    <FinalForm
      {...props}
      intl={intl}
      render={renderForm}
      marketplaceCurrency={config.currency}
      listingMinimumPriceSubUnits={config.listingMinimumPriceSubUnits}
      listingFieldsConfig={config.listing.listingFields}
      listingTypes={config.listing.listingTypes}
    />
  );
};

RequestSuggestionForm.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: {},
  hideSubmit: false,
  onSubmit: () => null,
  onChange: () => null,
  readonlyForm: false,
  hideDeliveryFields: false,
};

RequestSuggestionForm.propTypes = {
  rootClassName: string,
  className: string,

  // form
  formId: string.isRequired,
  onSubmit: func,
  onChange: func,
  hideSubmit: bool,

  listingType: string.isRequired,
  authorDisplayName: string.isRequired,
  unitType: string,

  initialValues: object,
  readonlyForm: bool,
  hideDeliveryFields: bool,
};

export default RequestSuggestionForm;
