import React from 'react';
import { Modal } from '../../../components';
import css from './RequestSuggestionModal.module.css';
import { any, bool, func, string } from 'prop-types';
import RequestSuggestionForm from '../RequestSuggestionForm/RequestSuggestionForm';

const RequestSuggestionModal = ({
  isModalOpen,
  onClose,
  onManageDisableScrolling,
  onSubmitQuote,
  ...restProps
}) => {
  return (
    <Modal
      id="ListingPage.requestSuggestion"
      contentClassName={css.modalContent}
      isOpen={isModalOpen}
      onClose={onClose}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
    >
      {restProps?.listingType === 'request' ? (
        <RequestSuggestionForm
          formId="ListingPage.requestSuggestionForm"
          onSubmit={onSubmitQuote}
          {...restProps}
        />
      ) : (
        <div />
      )}
    </Modal>
  );
};

RequestSuggestionModal.defaultProps = {};

RequestSuggestionModal.propTypes = {
  isModalOpen: bool.isRequired,
  onClose: func.isRequired,
  onSubmitQuote: func.isRequired,
  submitQuoteInProgress: bool,
  submitQuoteError: any,
  onManageDisableScrolling: func.isRequired,
  listingType: string.isRequired,
  authorDisplayName: string.isRequired,
  unitType: string.isRequired,
};

export default RequestSuggestionModal;
