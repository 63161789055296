import React from 'react';
import classNames from 'classnames';
import { array, bool, object, string } from 'prop-types';

import {
  FieldCheckbox,
  FieldCurrencyInput,
  FieldLocationAutocompleteInput,
  FieldTextInput,
} from '../../../components';
import { intlShape } from '../../../util/reactIntl';
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
  required,
} from '../../../util/validators';

import appSettings from '../../../config/settings';
import { STOCK_MULTIPLE_ITEMS } from '../../../util/types';

import css from './RequestSuggestionForm.module.css';

const identity = v => v;

const PickupAndDeliveryField = ({
  formId,
  intl,
  listingTypes,
  listingType,
  values,
  marketplaceCurrency,
  disabled,
}) => {
  const listingTypeConfig = listingTypes.find(conf => conf.listingType === listingType);
  const shippingEnabled = values.deliveryOptions?.includes('shipping');
  const pickupEnabled = values.deliveryOptions?.includes('pickup');
  const hasStockInUse = listingTypeConfig.stockType === STOCK_MULTIPLE_ITEMS;

  const shippingLabel = intl.formatMessage({
    id: 'RequestSuggestionForm.PickAndDeliveryField.shippingLabel',
  });
  const pickupLabel = intl.formatMessage({
    id: 'RequestSuggestionForm.PickAndDeliveryField.pickupLabel',
  });

  const addressRequiredMessage = intl.formatMessage({
    id: 'RequestSuggestionForm.PickAndDeliveryField.addressRequired',
  });
  const addressNotRecognizedMessage = intl.formatMessage({
    id: 'RequestSuggestionForm.PickAndDeliveryField.addressNotRecognized',
  });

  const optionalText = intl.formatMessage({
    id: 'RequestSuggestionForm.PickAndDeliveryField.optionalText',
  });

  const pickupClasses = classNames({
    [css.disabled]: !pickupEnabled,
  });

  const shippingClasses = classNames({
    [css.disabled]: !shippingEnabled,
  });

  const currencyConfig = appSettings.getCurrencyFormatting(marketplaceCurrency);

  return (
    <>
      <FieldCheckbox
        id="pickup"
        className={css.deliveryCheckbox}
        name="deliveryOptions"
        label={pickupLabel}
        value="pickup"
        disabled={disabled}
      />
      <div className={pickupClasses}>
        <FieldLocationAutocompleteInput
          disabled={!pickupEnabled || disabled}
          rootClassName={css.input}
          inputClassName={css.locationAutocompleteInput}
          iconClassName={css.locationAutocompleteInputIcon}
          predictionsClassName={css.predictionsRoot}
          validClassName={css.validLocation}
          name="location"
          label={intl.formatMessage({ id: 'RequestSuggestionForm.PickAndDeliveryField.address' })}
          placeholder={intl.formatMessage({
            id: 'RequestSuggestionForm.PickAndDeliveryField.addressPlaceholder',
          })}
          useDefaultPredictions={false}
          format={identity}
          valueFromForm={values.location}
          validate={
            pickupEnabled
              ? composeValidators(
                  autocompleteSearchRequired(addressRequiredMessage),
                  autocompletePlaceSelected(addressNotRecognizedMessage)
                )
              : () => {}
          }
          hideErrorMessage={!pickupEnabled}
          // Whatever parameters are being used to calculate
          // the validation function need to be combined in such
          // a way that, when they change, this key prop
          // changes, thus reregistering this field (and its
          // validation function) with Final Form.
          // See example: https://codesandbox.io/s/changing-field-level-validators-zc8ei
          key={pickupEnabled ? 'locationValidation' : 'noLocationValidation'}
        />

        <FieldTextInput
          className={css.input}
          type="text"
          name="building"
          id={`${formId}building`}
          label={intl.formatMessage(
            { id: 'RequestSuggestionForm.PickAndDeliveryField.building' },
            { optionalText }
          )}
          placeholder={intl.formatMessage({
            id: 'RequestSuggestionForm.PickAndDeliveryField.buildingPlaceholder',
          })}
          disabled={!pickupEnabled || disabled}
        />
      </div>

      <FieldCheckbox
        id="shipping"
        className={css.deliveryCheckbox}
        name="deliveryOptions"
        label={shippingLabel}
        value="shipping"
        disabled={disabled}
      />

      <div className={shippingClasses}>
        <FieldCurrencyInput
          id="shippingPriceInSubunitsOneItem"
          name="shippingPriceInSubunitsOneItem"
          className={css.input}
          label={intl.formatMessage({
            id: 'RequestSuggestionForm.PickAndDeliveryField.shippingOneItemLabel',
          })}
          placeholder={intl.formatMessage({
            id: 'RequestSuggestionForm.PickAndDeliveryField.shippingOneItemPlaceholder',
          })}
          currencyConfig={currencyConfig}
          disabled={!shippingEnabled || disabled}
          validate={
            shippingEnabled
              ? required(
                  intl.formatMessage({
                    id: 'RequestSuggestionForm.PickAndDeliveryField.shippingOneItemRequired',
                  })
                )
              : null
          }
          hideErrorMessage={!shippingEnabled}
          // Whatever parameters are being used to calculate
          // the validation function need to be combined in such
          // a way that, when they change, this key prop
          // changes, thus reregistering this field (and its
          // validation function) with Final Form.
          // See example: https://codesandbox.io/s/changing-field-level-validators-zc8ei
          key={shippingEnabled ? 'oneItemValidation' : 'noOneItemValidation'}
        />

        {hasStockInUse ? (
          <FieldCurrencyInput
            id="shippingPriceInSubunitsAdditionalItems"
            name="shippingPriceInSubunitsAdditionalItems"
            className={css.input}
            label={intl.formatMessage({
              id: 'RequestSuggestionForm.PickAndDeliveryField.shippingAdditionalItemsLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'RequestSuggestionForm.PickAndDeliveryField.shippingAdditionalItemsPlaceholder',
            })}
            currencyConfig={currencyConfig}
            disabled={!shippingEnabled || disabled}
            validate={
              shippingEnabled
                ? required(
                    intl.formatMessage({
                      id:
                        'RequestSuggestionForm.PickAndDeliveryField.shippingAdditionalItemsRequired',
                    })
                  )
                : null
            }
            hideErrorMessage={!shippingEnabled}
            // Whatever parameters are being used to calculate
            // the validation function need to be combined in such
            // a way that, when they change, this key prop
            // changes, thus reregistering this field (and its
            // validation function) with Final Form.
            // See example: https://codesandbox.io/s/changing-field-level-validators-zc8ei
            key={shippingEnabled ? 'additionalItemsValidation' : 'noAdditionalItemsValidation'}
          />
        ) : null}
      </div>
    </>
  );
};

PickupAndDeliveryField.defaultProps = {
  disabled: false
};

PickupAndDeliveryField.propTypes = {
  formId: string.isRequired,
  intl: intlShape.isRequired,
  listingType: string.isRequired,
  listingTypes: array.isRequired,
  values: object,
  marketplaceCurrency: string.isRequired,
  disabled: bool,
};

export default PickupAndDeliveryField;
